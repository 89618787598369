import * as yup from "yup";

const editorDataVal = yup.object().shape({
  blocks: yup.array().optional(),
  time: yup.number().optional(),
  version: yup.string().optional(),
});

export const validationSchema = yup.object({
  app_name: yup.string().required("Please enter an App name."),
  web_footer_html: editorDataVal,
  web_footer_html_html: yup.object().shape({
    html: yup.string(),
  }),
});
