import { UseMutationResult } from "@tanstack/react-query";
import { Button, Col, Row } from "antd";
import { Formik, FormikProps } from "formik";
import { Form, Input, Select } from "formik-antd";
import React from "react";

import { getOutcomeQuestionName } from "../../assets/data/outcomeDiaries";
import {
  OutcomeQuestion,
  OutcomeQuestionType,
  PortalClientError,
  UpdateOutcomeQuestion,
} from "../../client";
import { JsonApiResponse } from "../../client/hooks/types";
import { FormProps } from "../../types";
import { snakeCase } from "../../utils/format";
import { removeCircularQuestionReferences } from "../../utils/outcomeDiaries";
import defaultOutcomeQuestion from "./defaults";
import OutcomeQuestionConfiguration from "./OutcomeQuestionConfiguration";
import { OutcomeQuestionPreview } from "./OutcomeQuestionPreview";
import outcomeQuestionValidation from "./outcomeQuestionValidation";
import { getOutcomeQuestionPayload } from "./utils";

export interface OutcomeQuestionFormProps extends FormProps<OutcomeQuestion> {
  disabled?: boolean;
  mutation:
    | UseMutationResult<
        JsonApiResponse<OutcomeQuestion>,
        PortalClientError,
        UpdateOutcomeQuestion
      >
    | UseMutationResult<
        JsonApiResponse<OutcomeQuestion>,
        PortalClientError,
        UpdateOutcomeQuestion
      >;
  outcomeQuestion: OutcomeQuestion;
}

const OutcomeQuestionForm: React.FunctionComponent<
  OutcomeQuestionFormProps
> = ({ disabled = false, mutation, onSuccess, outcomeQuestion }) => {
  const handleSubmit = async (values: OutcomeQuestion) => {
    const payload = getOutcomeQuestionPayload(values);
    const result = await mutation.mutateAsync(payload);
    const cleanResult = removeCircularQuestionReferences(result.data);
    onSuccess?.(cleanResult);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={outcomeQuestion}
      key="outcome_question_form"
      onSubmit={handleSubmit}
      validateOnBlur
      validationSchema={outcomeQuestionValidation}
    >
      {({
        isValid,
        setValues,
        setFieldValue,
        submitForm,
        values,
      }: FormikProps<OutcomeQuestion>) => (
        <Row wrap={false}>
          <Col span={15}>
            <Form
              key="outcome_question_form"
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 15 }}
            >
              <Form.Item label="Identifier" name="identifier">
                <Input
                  autoFocus={!outcomeQuestion.id}
                  disabled={disabled || !!outcomeQuestion.id}
                  name="identifier"
                  onChange={(e) =>
                    setFieldValue(
                      "identifier",
                      snakeCase(e.currentTarget.value)
                    )
                  }
                  placeholder="Enter an identifier"
                />
              </Form.Item>
              <Form.Item
                label="Answer Type"
                name="ui_element"
                wrapperCol={{ span: 10 }}
              >
                <Select
                  disabled={disabled}
                  name="ui_element"
                  onChange={(value: OutcomeQuestionType) => {
                    setValues({
                      ...defaultOutcomeQuestion[value],
                      identifier: values.identifier,
                    });
                  }}
                >
                  {Object.values(OutcomeQuestionType).map((kind) => (
                    <Select.Option key={kind} value={kind}>
                      {getOutcomeQuestionName(kind)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item label="Question" name="configuration.question">
                <Input
                  disabled={disabled}
                  name="configuration.question"
                  placeholder="Enter a question"
                />
              </Form.Item>

              <OutcomeQuestionConfiguration
                disabled={disabled}
                outcomeQuestion={values}
              />
              {values.ui_element !== OutcomeQuestionType.Buttons &&
                values.ui_element !==
                  OutcomeQuestionType.PreemptiveAIExternalLink && (
                  <React.Fragment>
                    <Form.Item
                      label="Tip Title (optional)"
                      name="configuration.quick_tip.title"
                    >
                      <Input
                        disabled={disabled}
                        name="configuration.quick_tip.title"
                        placeholder="Tip Title"
                      />
                    </Form.Item>
                    <Form.Item
                      label="Tip Body (optional)"
                      name="configuration.quick_tip.body"
                    >
                      <Input.TextArea
                        autoSize={{ minRows: 3 }}
                        disabled={disabled}
                        name="configuration.quick_tip.body"
                        placeholder="Tip Body"
                      />
                    </Form.Item>
                  </React.Fragment>
                )}
              <Form.Item
                name="buttons"
                wrapperCol={{ push: 10, span: 3, flex: "center" }}
              >
                <Button
                  disabled={disabled || mutation.isLoading || !isValid}
                  onClick={submitForm}
                  type="primary"
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Col>
          <Col span={8}>
            <OutcomeQuestionPreview outcomeQuestion={values} />
          </Col>
        </Row>
      )}
    </Formik>
  );
};
export default OutcomeQuestionForm;
