import React from "react";

import { useExperiments } from "../../client";

interface FeatureFlaggedContentProps {
  experimentName?: string;
}

const FeatureFlaggedContent: React.FunctionComponent<
  FeatureFlaggedContentProps
> = ({ experimentName, children, ...props }) => {
  const { data: experiment_configs } = useExperiments();

  const canOptionBeRendered =
    experiment_configs?.data.some(
      (experiment) => experiment.name === experimentName && experiment.active
    ) ?? false;

  if (!React.isValidElement(children)) {
    return null;
  }

  if (canOptionBeRendered) {
    // props are necessary to inherit the ant-menu styles
    return React.cloneElement(children, props);
  } else {
    return null;
  }
};

export default FeatureFlaggedContent;
