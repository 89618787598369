import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";

import App from "./App";
import { ClientProviderEnvironment } from "./client";
import { FeatureToggleProvider } from "./client/FeatureToggleContext";
import { unregisterServiceWorker } from "./registerServiceWorker";

import "antd/dist/antd.css";
import "./index.css";

declare global {
  interface Window {
    env: {
      REACT_APP_PORTAL_ENVIRONMENT?: ClientProviderEnvironment;
      [key: string]: any;
    };
  }
}

ReactDOM.render(
  <Router>
    <FeatureToggleProvider>
      <App />
    </FeatureToggleProvider>
  </Router>,
  document.getElementById("root")
);

unregisterServiceWorker();
