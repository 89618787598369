export const defaultHtmlFooter = `
  <div>
    <style>
      footer {
        font-family: 'Public Sans', sans-serif;
        padding: 24px 120px;
        background-color: #f9fafb;
        display: flex;
        justify-content: space-between;
      }
      a {
        color: #162d6d;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-decoration: underline;
      }
      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
      }
      p {
        margin: 0;
      }
      .links-wrapper {
        width: 402px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .copyright {
        color: #1a1a1a;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
    </style>
    <footer>
      <img
        src='https://cdn.prod.website-files.com/61faadbd3ec7085e2c97a896/623c8ec331d301f00d735333_logo-evidation-black.svg'
        alt='Evidation logo'
        width='122'
      />
      <ul class='links-wrapper'>
        <li>
          <a href='https://evidation.com/contact'>Contact Us</a>
        </li>
        <li>
          <a href='https://evidation.com/terms'>Terms & Conditions</a>
        </li>
        <li>
          <a href='https://evidation.com/privacy'>Privacy Policy</a>
        </li>
      </ul>
      <p class='copyright'>Evidation @ 2024</p>
    </footer>
  </div>
`;
