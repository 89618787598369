import EditorJS from "@editorjs/editorjs";
import { OutputData } from "@editorjs/editorjs";
import Embed from "@editorjs/embed";
import Header from "@editorjs/header";
import RawTool from "@editorjs/raw";
import { Card } from "antd";
import DragDrop from "editorjs-drag-drop";
import Undo from "editorjs-undo";
import _ from "lodash";
import { useEffect, useRef } from "react";

declare global {
  interface Window {
    editorData: OutputData;
    editor?: EditorJS;
    editorChanged?: boolean;
  }
}

const ContentEditor = (
  {
    data,
    onChange,
    readOnly = false,
    id = "editor",
  }: {
    data: OutputData | any;
    onChange?: (s: OutputData) => any;
    readOnly?: boolean;
    id?: string;
  } = { data: undefined, onChange: undefined }
) => {
  const editorRef = useRef<any>(null);
  const prevDataRef = useRef<OutputData | null>(null);

  const initializeEditor = async () => {
    // Import tools on the fly for speed

    const mainTools = {
      header: {
        class: Header,
      },
      embed: {
        class: Embed,
      },
      raw: {
        class: RawTool,
      },
    };
    const editor = new EditorJS({
      autofocus: false,
      holder: id,
      readOnly,
      onReady: () => {
        new Undo({ editor });
        new DragDrop(editor);
        editorRef.current = editor;
        window.editor = editor;
      },
      onChange: () => {
        editor.save().then((s) => {
          // attempts to only call the external onChange with real changes.
          if (!_.isEqual(s, prevDataRef.current)) {
            onChange?.(s);
            prevDataRef.current = s;
          }
        });
      },
      minHeight: 100,
      //@ts-expect-error
      tools: {
        ...mainTools,
      },
      data: data || { blocks: [] },
    });
  };
  useEffect(() => {
    if (!editorRef.current || !editorRef.current.isReady) {
      initializeEditor();
    }
    return () => {
      editorRef.current?.destroy();
      editorRef.current = null;
    };
    //eslint-disable-next-line -- We only want this to run once, we dont care about initializeEditor (yet)
  }, []);
  /**
   * If the initial editor.js schema changes check and update it.
   * */
  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.save().then((outputData: OutputData) => {
        if (!_.isEqual(outputData?.blocks, data?.blocks)) {
          editorRef.current.render(data);
        }
      });
    }
  }, [data]);

  return <Card id={id}></Card>;
};

export default ContentEditor;
