import { Space, Typography } from "antd";

import { Brand } from "../../../client/hooks/brands/types";
import FontsForm from "../../../forms/CustomizationForms/FontsForm";

type Props = {
  brand: Brand;
};

const FontsTab: React.FC<Props> = ({ brand }) => {
  return (
    <Space direction="vertical" size={24}>
      <Typography.Title level={4}>Fonts</Typography.Title>
      <FontsForm brand={brand} />
    </Space>
  );
};
export default FontsTab;
