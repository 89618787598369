import { Space, Typography } from "antd";

import { Brand } from "../../../client/hooks/brands/types";
import LogosForm from "../../../forms/CustomizationForms/LogosForm";

type Props = {
  brand: Brand;
};

const LogosTab: React.FC<Props> = ({ brand }) => {
  return (
    <Space direction="vertical" size={24}>
      <Typography.Title level={4}>Images</Typography.Title>
      <LogosForm brand={brand} />
    </Space>
  );
};
export default LogosTab;
