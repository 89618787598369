import { Button, Image as AntImage, Space } from "antd";
import React from "react";

import { Image } from "../../client";
import SelectImageModal from "../../modals/SelectImageModal";

interface SelectImageButtonProps {
  disabled?: boolean;
  id?: string;
  hasPreview?: boolean;
  image?: Image;
  kind: string;
  onClearImage: () => void;
  onSelectImage: (image: Image) => void;
}

const SelectImageButton: React.FC<SelectImageButtonProps> = ({
  disabled,
  id,
  hasPreview = false,
  image,
  kind,
  onClearImage,
  onSelectImage,
}) => {
  return (
    <Space direction="vertical" size="middle">
      {hasPreview && image && image?.id && (
        <AntImage alt={image.alt_text} src={image.src} width={200} />
      )}
      <SelectImageModal
        kind={kind}
        onSelectImage={onSelectImage}
        renderButton={(open) => (
          <React.Fragment>
            <Button disabled={disabled} id={id} onClick={open} type="primary">
              {image ? "Update Image" : "Add Image"}
            </Button>
            {image?.id && (
              <Button
                disabled={disabled}
                onClick={onClearImage}
                style={{ marginLeft: "8px" }}
                type="ghost"
              >
                Remove Image
              </Button>
            )}
          </React.Fragment>
        )}
      />
    </Space>
  );
};
export default SelectImageButton;
