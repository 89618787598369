import { Button, Upload, UploadFile } from "antd";
import React from "react";

import { Override } from "../utils/types";

type UploadProps = React.ComponentProps<typeof Upload>;
type ImageUploadProps = Override<
  UploadProps,
  {
    name: string;
    onChange: (value: UploadFile<File>) => void;
    defaultValue?: string;
    onRemove: () => void;
  }
>;

const ImageUpload: React.FC<ImageUploadProps> = ({
  name,
  defaultValue,
  onChange,
  onRemove,
  ...props
}) => {
  return (
    <Upload
      {...props}
      accept="image/jpg,image/png,image/jpeg,image/svg+xml"
      beforeUpload={() => false}
      defaultFileList={[
        {
          uid: name,
          name,
          status: "done",
          url: defaultValue,
        },
      ]}
      listType="picture-card"
      maxCount={1}
      name={name}
      onChange={({ file }) => onChange(file)}
      onRemove={onRemove}
    >
      <Button>Upload</Button>
    </Upload>
  );
};

export default ImageUpload;
