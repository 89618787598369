import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import type { PortalClientError } from "../../types";
import type { JsonApiResponse } from "../types";
import { Brand } from "./types";

const useBrand = <T = JsonApiResponse<Brand>>(
  queryOptions?: UseQueryOptions<JsonApiResponse<Brand>, PortalClientError, T>
) => {
  const client = useClient();

  return useQuery<JsonApiResponse<Brand>, PortalClientError, T>(
    ["brands"],
    () => client.get("/brand"),
    {
      refetchOnWindowFocus: false,
      ...queryOptions,
    }
  );
};

export default useBrand;
