import { Select, Space, Typography } from "antd";
import { useFormikContext } from "formik";
import { Form } from "formik-antd";
import React from "react";

import { BrandFontAttributes } from "../../client/hooks/brands/types";
import { FontGroupProps } from "../../forms/CustomizationForms/FontsForm/FontsForm.types";

const DEFAULT_OPTION = {
  label: "-- Default --",
  value: "",
};

const FontGroup: React.FC<FontGroupProps> = ({
  keyName,
  title,
  description,
}) => {
  const { initialValues, touched, setFieldValue, validateField } =
    useFormikContext<BrandFontAttributes>();

  const fieldName = (type: "family" | "weight" | "size") =>
    `${keyName}_font_${type}` as const;
  const fontFamilyField = fieldName("family");
  const fontWeightField = fieldName("weight");
  const fontSizeField = fieldName("size");

  return (
    <div aria-label={title} role="region">
      <Space direction="vertical" size={16}>
        <Typography.Title level={5} style={{ margin: 0 }}>
          {title}
        </Typography.Title>

        <div>
          <Typography.Text type="secondary">{description}</Typography.Text>
        </div>

        <Space direction="horizontal" size={16}>
          <Space direction="vertical" size={4} style={{ width: 200 }}>
            <Typography.Text strong>Font Family</Typography.Text>

            <Form.Item
              hasFeedback={touched[fontFamilyField]}
              name={fontFamilyField}
            >
              <Select
                aria-label="Font Family"
                defaultActiveFirstOption
                defaultValue={initialValues[fontFamilyField] ?? ""}
                onChange={(value) => {
                  setFieldValue(fontFamilyField, value || null);
                  validateField(fontFamilyField);
                }}
                options={[
                  DEFAULT_OPTION,
                  {
                    label: "Georgia",
                    value: "Georgia",
                  },
                  {
                    label: "Lub Dub",
                    value: "Lub Dub",
                  },
                  {
                    label: "Montserrat",
                    value: "Montserrat",
                  },
                  {
                    label: "Public Sans",
                    value: "Public Sans",
                  },
                  {
                    label: "Source Serif Pro",
                    value: "Source Serif Pro",
                  },
                ]}
              />
            </Form.Item>
          </Space>

          <Space direction="vertical" size={4} style={{ width: 200 }}>
            <Typography.Text strong>Font Weight</Typography.Text>

            <Form.Item
              hasFeedback={touched[fontWeightField]}
              name={fontWeightField}
            >
              <Select
                aria-label="Font Weight"
                defaultActiveFirstOption
                defaultValue={initialValues[fontWeightField] ?? ""}
                onChange={(value) => {
                  setFieldValue(fontWeightField, value || null);
                  validateField(fontWeightField);
                }}
                options={[
                  DEFAULT_OPTION,
                  { label: "Regular", value: "Regular" },
                  { label: "Semibold", value: "Semibold" },
                  { label: "Bold", value: "Bold" },
                ]}
              />
            </Form.Item>
          </Space>

          <Space direction="vertical" size={4} style={{ width: 200 }}>
            <Typography.Text strong>Font Size</Typography.Text>

            <Form.Item
              hasFeedback={touched[fontSizeField]}
              name={fontSizeField}
            >
              <Select
                aria-label="Font Size"
                defaultActiveFirstOption
                defaultValue={initialValues[fontSizeField] ?? ""}
                onChange={(value) => {
                  setFieldValue(fontSizeField, value || null);
                  validateField(fontSizeField);
                }}
                options={[
                  DEFAULT_OPTION,
                  { label: "14", value: "14" },
                  { label: "16", value: "16" },
                  { label: "18", value: "18" },
                  { label: "20", value: "20" },
                  { label: "22", value: "22" },
                  { label: "24", value: "24" },
                  { label: "26", value: "26" },
                  { label: "28", value: "28" },
                  { label: "32", value: "32" },
                  { label: "36", value: "36" },
                  { label: "40", value: "40" },
                  { label: "44", value: "44" },
                  { label: "48", value: "48" },
                ]}
              />
            </Form.Item>
          </Space>
        </Space>
      </Space>
    </div>
  );
};
export default FontGroup;
