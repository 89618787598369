import * as yup from "yup";

import imageFile from "../utils/imageFile";

export const schema = yup.object({
  admin_login_text: yup.string(),
  admin_welcome_message: yup.string(),
  admin_favicon_url: imageFile(),
  admin_logo_url: imageFile(),
});
