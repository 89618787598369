import * as yup from "yup";

import { OutcomeQuestionType } from "../../client";

export const MIN_OPTIONS = 2;
export const MAX_OPTIONS = 5;
export const BUTTON_AMOUNT = 2;

const configurationSchema = yup
  .object({
    quick_tip: yup.object({
      body: yup.string(),
      title: yup.string(),
    }),
  })
  .when("ui_element", {
    is: (value) => value !== OutcomeQuestionType.Buttons,
    then: yup.object({
      question: yup.string().required("Please enter an outcome question."),
    }),
  })
  .when("ui_element", {
    is: OutcomeQuestionType.CheckboxGroup,
    then: yup
      .object({
        checkboxes: yup
          .array()
          .of(
            yup.object({
              exclusive: yup.boolean(),
              label: yup
                .string()
                .required(
                  "Please enter a label for the outcome question option."
                ),
              value: yup.string().required(),
            })
          )
          .min(MIN_OPTIONS, "Please enter at least 2 options.")
          .required(),
      })
      .required(),
  })
  .when("ui_element", {
    is: OutcomeQuestionType.RadioButtons,
    then: yup
      .object({
        checkboxes: yup
          .array()
          .of(
            yup.object({
              exclusive: yup.boolean(),
              label: yup
                .string()
                .required(
                  "Please enter a label for the outcome question option."
                ),
              value: yup.string().required(),
            })
          )
          .min(MIN_OPTIONS, "Please enter at least 2 options.")
          .required(),
      })
      .required(),
  })
  .when("ui_element", {
    is: OutcomeQuestionType.Buttons,
    then: yup
      .object({
        question: yup.string().nullable(),
        buttons: yup
          .array()
          .of(
            yup.object({
              label: yup
                .string()
                .required(
                  "Please enter a label for the outcome question option."
                ),
              value: yup.string().required(),
            })
          )
          .min(BUTTON_AMOUNT, "Please enter at least 2 one-click options.")
          .max(BUTTON_AMOUNT, "You can only have up 2 one-click options.")
          .required(),
      })
      .required(),
  })
  .when("ui_element", {
    is: OutcomeQuestionType.PreemptiveAIExternalLink,
    then: yup
      .object({
        question: yup.string().nullable(),
      })
      .required(),
  });

const outcomeQuestionValidation = yup
  .object({
    identifier: yup.string().required(),
    ui_element: yup
      .string()
      .oneOf(Object.values(OutcomeQuestionType))
      .required(),
    configuration: configurationSchema,
    responseSchema: yup.object(),
  })
  .nullable();
export default outcomeQuestionValidation;
