import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import type { PortalClientError } from "../..";
import { useClient, useQueryClient } from "../../ClientContext";
import type { JsonApiResponse } from "../types";
import type { Brand } from "./types";

type UpdatePayload = {
  brand: Partial<Brand>;
};

const useUpdateBrand = (
  queryOptions?: UseMutationOptions<
    JsonApiResponse<Brand>,
    PortalClientError,
    Brand
  >
) => {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation(
    async (brand) => {
      const data: UpdatePayload = { brand };
      return await client.put<any, JsonApiResponse<Brand>, UpdatePayload>(
        "/brand",
        data
      );
    },
    {
      ...queryOptions,
      onSuccess: (brand) => {
        queryClient.setQueryData(["brands"], brand);

        if (queryOptions?.onSuccess) {
          queryOptions.onSuccess(brand, brand.data, brand);
        }
      },
    }
  );
};

export default useUpdateBrand;
