import * as yup from "yup";

export const schema = yup.object({
  accent_text_font_family: yup.string().nullable(),
  accent_text_font_size: yup.string().nullable(),
  accent_text_font_weight: yup.string().nullable(),
  body_text_1_font_family: yup.string().nullable(),
  body_text_1_font_size: yup.string().nullable(),
  body_text_1_font_weight: yup.string().nullable(),
  body_text_2_font_family: yup.string().nullable(),
  body_text_2_font_size: yup.string().nullable(),
  body_text_2_font_weight: yup.string().nullable(),
  body_text_3_font_family: yup.string().nullable(),
  body_text_3_font_size: yup.string().nullable(),
  body_text_3_font_weight: yup.string().nullable(),
  heading_1_font_family: yup.string().nullable(),
  heading_1_font_size: yup.string().nullable(),
  heading_1_font_weight: yup.string().nullable(),
  heading_2_font_family: yup.string().nullable(),
  heading_2_font_size: yup.string().nullable(),
  heading_2_font_weight: yup.string().nullable(),
  heading_3_font_family: yup.string().nullable(),
  heading_3_font_size: yup.string().nullable(),
  heading_3_font_weight: yup.string().nullable(),
  heading_4_font_family: yup.string().nullable(),
  heading_4_font_size: yup.string().nullable(),
  heading_4_font_weight: yup.string().nullable(),
  heading_5_font_family: yup.string().nullable(),
  heading_5_font_size: yup.string().nullable(),
  heading_5_font_weight: yup.string().nullable(),
});
