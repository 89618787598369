const Notifications = {
  ActivateOffer: {
    success: "Offer has been successfully activated",
    error: "There was a problem activating this offer",
  },
  ActivateProgram: {
    success: "Program has been successfully activated",
    error: "There was a problem activating this program",
  },
  ActivateRecurring: {
    success: "Recurring engagement has been successfully activated",
    error: "There was a problem activating this recurring engagement",
  },
  AddAdmin: {
    success: "The administrator was created",
    error: "There was a problem creating a new administrator",
  },
  AddAppSettings: {
    success: "The app settings were created",
    error: "There was a problem updating app settings:",
  },
  AddApplication: {
    success: "The application was created",
    error: "There was a problem creating a new application",
  },
  AddDeepLink: {
    success: "The deep link was created",
    error: "There was a problem creating a new deep link: ",
  },
  AddExperiment: {
    success: "The experiment was created",
    error: "There was a problem creating a new experiment",
  },
  AddImage: {
    success: "The image was successfully uploaded",
    error: "There was a problem uploading the image",
  },
  AddInsightType: {
    success: "The insight type was successfully created",
    error: "There was a problem creating the insight type: ",
  },
  AddOffer: {
    success: "The offer was successfully created",
    error: "There was a problem creating the offer",
  },
  AddOutcomDiary: {
    success: "The outcome diary was successfully created",
    error: "There was a problem creating the outcome diary",
  },
  AddProgram: {
    success: "The program was successfully created",
    error: "There was a problem creating the program",
  },
  AddSurveyQuestion: {
    success: "The survey question was successfully created for the offer",
    error: "There was a problem creating the survey question",
  },
  AddOutcomeQuestion: {
    success: "The outcome question was successfully created",
    error: "There was a problem creating the outcome question",
  },
  AddUserNote: {
    success: "The notes were successfully created",
    error: "There was a problem creating the notes: ",
  },
  AllowlistUser: {
    success: "The user was successfully removed from the denylist",
    error: "There was a problem removing the user from the denylist:",
  },
  AwardBonusPoints: {
    success: "The bonus points were successfully awarded",
    error: "There was a problem awarding bonus points: ",
  },
  AwardRefereePoints: {
    success: "The referee points were successfully awarded",
    error: "There was a problem awarding referee points: ",
  },
  CCPADeletion: {
    success: "The CCPA deletion process has successfully started",
    error: "There was a problem starting the CCPA deletion process",
  },
  DenylistUser: {
    success: "The user was successfully denylisted",
    error: "There was a problem denylisting this user: ",
  },
  UpdateOutcomeQuestion: {
    success: "The outcome question was successfully updated",
    error: "There was a problem updating the outcome question",
  },
  CloneOffer: {
    success: "The offer was successfully cloned",
    error: "There was a problem cloning the offer",
  },
  DeactivateOffer: {
    success: "Offer has been successfully deactivated",
    error: "There was a problem deactivating this offer",
  },
  DeactivateProgram: {
    success: "Program has been successfully deactivated",
    error: "There was a problem deactivating this program",
  },
  DeactivateRecurring: {
    success: "Recurring engagement has been successfully deactivated",
    error: "There was a problem deactivating this recurring engagement",
  },
  DeactivateUser: {
    success: "The user has been successfully deactivated",
    error: "There was a problem deactivating this user: ",
  },
  DeleteAdmin: {
    success: "The administrator was removed",
    error: "There was a problem removing the administrator",
  },
  DeleteInsightType: {
    success: "The insight type was deleted",
    error: "There was a problem deleting the insight type: ",
  },
  DeleteUser: {
    success: "The user was deleted",
    error: "There was a problem deleting the user: ",
  },
  IssuePRO: {
    success: "The PRO was issued successfully",
    error: "There was a problem issuing the PRO",
  },
  LoadApplication: {
    error: {
      title: "Error loading application (ID: ",
      subtitle:
        "There was a problem loading this application, please try again.",
    },
  },
  ReactivateUser: {
    success: "The user has been successfully reactivated",
    error: "There was a problem reactivating this user: ",
  },
  RecalculateUserPoints: {
    success: "User points have been recalculated",
    error: "There was a problem recalculating this user's points: ",
  },
  ResendReward: {
    success: "The reward was resent",
    error: "There was a problem resending this reward: ",
    undefinedError:
      "There was a problem resending this reward, please try again.",
  },
  ResetOfferInteractions: {
    success: "Success - offer interaction was reset ",
    error: "Error - could not reset offer interaction",
    noUser: "Could not find associated user",
  },
  ResetOfferTargeting: {
    success: "Success - offer targeting was reset for your admin account",
    error: "Error - could not reset offer targeting for your admin account",
  },
  ResetUserPassword: {
    success: "Success - the user has been sent a reset password email",
    error: "Error - there was a problem sending the users reset password email",
  },
  SendEmail: {
    success: "Your e-mail has been sent",
    error: "We had trouble sending your e-mail",
  },
  SendOffer: {
    success: "Your offer has been sent",
    error: "We had trouble sending your offer",
  },
  SendTestEmail: {
    success: "Your test e-mail has been sent",
    error: "We had trouble sending your test e-mail",
  },
  SendTestOffer: {
    success: "Your test in-app offer has been sent to your account",
    error: "We had trouble sending your test in-app offer",
  },
  UpdateApplication: {
    success: "The application was updated",
    error: "There was a problem updating the application",
  },
  UpdateAdmin: {
    success: "The administrator was updated",
    error: "There was a problem updating the administrator",
  },
  UpdateDeepLink: {
    success: "The deep link was updated successfully",
    error: "There was a problem updating the deep link: ",
  },
  UpdateExperiment: {
    success: "The experiment was updated",
    error: "There was a problem updating the experiment",
  },
  UpdateImage: {
    success: "The image was updated",
    error: "There was a problem updating the image",
  },
  UdpateInsightType: {
    success: "The insight type was updated",
    error: "There was a problem updating the insight type: ",
  },
  UpdateOffer: {
    success: "Your offer has been updated",
    error: "We had trouble updating the offer",
  },
  UpdateRecurringEngagement: {
    success: "Your recurring engagement has been updated",
    error: "We had trouble updating the recurring engagement",
  },
  UpdateProgram: {
    success: "Your program has been updated",
    error: "We had trouble updating the program",
  },
  UpdateSurveyQuestion: {
    success: "The survey question has been updated for this offer",
    error: "We had trouble updating the survey question",
  },
  UpdateSynapseRule: {
    error: "There was a problem updating the draft rule",
  },
  UpdateUser: {
    success: "The user was updated",
    error: "There was a problem updating the user: ",
  },
  UpdateWorkspaceSettings: {
    success: "The workspace settings were successfully updated.",
    error: "There was a problem updating the workspace settings: ",
  },
  UpdateCustomization: {
    success: "Customization settings were successfully updated.",
    error: "There was a problem updating customization settings: ",
  },
  UserProgramUnenroll: {
    success: "The user was successfully unenrolled.",
    error: "There was a problem unenrolling the user: ",
  },
  CreateOfferCSV: {
    error: "We had trouble updating the offer's CSV",
  },
  UploadZipcodeCSV: {
    error: "We had trouble uploading the zipcode CSV.",
  },
  ProgramUnenroll: {
    success: "The bulk unenrollment process has successfully started",
    error1: "We had trouble calculating the number of users to unenroll",
    error2: "We had trouble starting the bulk unenrollment process",
  },
  PublishSynapseRule: {
    success: "Rule published successfully",
    error: "There was a problem publishing the draft rule: ",
  },
  VerifyUser: {
    success: "The user has been successfully verified",
    error: "There was a problem verifying this user: ",
  },
  UpdateFeature: {
    success: "The feature has been successfully updated",
    error: "There as a problem updating the feature",
  },
  Features: {
    error: "There was a problem loading the features list.",
  },
};
export default Notifications;
