import { get, isEqual, keys } from "lodash";

import { ExpandType } from "./types";

export const getChangedValues = <T extends Object>(
  initialValues?: T | null,
  values?: T | null
): Partial<T> => {
  return keys(values).reduce<T>((accumulator: T, key: string) => {
    if (isEqual(get(initialValues, key), get(values, key))) {
      return accumulator;
    }
    return {
      ...accumulator,
      [key]: get(values, key),
    };
  }, {} as any);
};

type Inverted<T extends Record<PropertyKey, PropertyKey>> = {
  [K in keyof T as T[K]]: K;
};

/**
 * Returns an inverted copy of the object such that values become keys and keys
 *   become values. Preserves constant object types, unlike Lodash's
 *   implementation.
 */
export const invert = <T extends Record<PropertyKey, PropertyKey>>(
  object: T
): ExpandType<Inverted<T>> =>
  Object.entries(object).reduce((result: any, [key, value]) => {
    result[value] = key;
    return result;
  }, {} as Inverted<T>);
