import { Space, Typography } from "antd";

import { Brand } from "../../../client/hooks/brands/types";
import GeneralForm from "../../../forms/CustomizationForms/GeneralForm";

type GeneralTabProps = {
  brand: Brand;
};

const GeneralTab = ({ brand }: GeneralTabProps) => {
  return (
    <Space direction="vertical" size={24}>
      <Typography.Title level={4}>General</Typography.Title>
      <GeneralForm brand={brand} />
    </Space>
  );
};

export default GeneralTab;
