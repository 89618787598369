import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import type { PortalClientError } from "../../types";
import type { JsonApiResponse } from "../types";

export type TenantFeatureFlagDescription = {
  key: string;
  category: string;
  feature: string;
  description: string;
  application: Array<string>;
  default: boolean;
};

export type WorkspaceSettings = {
  admin_login_text: string;
  admin_welcome_message: string;
  admin_favicon_url: string;
  admin_logo_url: string;
  tenant_feature_flag_descriptions: TenantFeatureFlagDescription[];
};

const useWorkspaceSettings = (
  queryOptions?: UseQueryOptions<
    JsonApiResponse<WorkspaceSettings>,
    PortalClientError
  >
) => {
  const client = useClient();

  return useQuery<JsonApiResponse<WorkspaceSettings>, PortalClientError>(
    ["workspace-settings"],
    () => client.get("/workspace_settings"),
    {
      refetchOnWindowFocus: false,
      ...queryOptions,
      retry: (_failureCount, error) =>
        // 404 means the settings aren't set, so no point in retrying.
        error.status === 404 ? false : true,
    }
  );
};

export default useWorkspaceSettings;
