import { Text } from "@evidation-shared/eve";
import React from "react";

import {
  CheckboxOptionGroup,
  RadioOptionGroup,
} from "./formComponents/EveOptionGroup";
import { OutcomeQuestion } from "./types";

type OutcomeDiaryInputProps = {
  question: OutcomeQuestion;
  bordered?: boolean;
};
const OutcomeDiaryInput: React.FunctionComponent<OutcomeDiaryInputProps> = ({
  bordered = false,
  question,
}) => {
  switch (question.ui_element) {
    case "checkbox_group":
      return question.configuration.checkboxes ? (
        <CheckboxOptionGroup
          options={question.configuration.checkboxes}
          variant={bordered ? "bordered" : "default"}
        />
      ) : null;
    case "radiobuttons":
      return question.configuration.checkboxes ? (
        <RadioOptionGroup
          name="radioGroup"
          options={question.configuration.checkboxes}
          variant={bordered ? "bordered" : "default"}
        />
      ) : null;
    case "buttons":
    case "preemptive_ai_external_link":
      return null;
    default:
      return (
        //typescript is expecting every single prop, even optional props, to be defined.
        //@ts-ignore
        <Text>{"Invalid Component:" + question.ui_element}</Text>
      );
  }
};

export default OutcomeDiaryInput;
