/**
 * All of the roles available to Maestro users.
 *
 * @todo: Document these.
 */
const roles = {
  applicationAdmin: "application_admin",
  adminsAdmin: "admins_admin",
  deepLinkAdmin: "deep_link_admin",
  experimentConfigAdmin: "experiment_config_admin",
  imageManager: "image_manager",
  insightAdmin: "insight_admin",
  programAdmin: "program_admin",
  offerAdmin: "offer_admin",
  superAdmin: "super_admin",
  userManager: "user_manager",
};
export default roles;
