import { HStack, VStack } from "@evidation-shared/eve";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Space, notification, Form, Typography, Input } from "antd";
import editorToHtml from "editorjs-html";
import { useForm } from "react-hook-form";

import Notifications from "../../../assets/data/notifications";
import { PortalClientError } from "../../../client";
import useUpdateBrand from "../../../client/hooks/brands/useUpdateBrand";
import ContentEditor from "../../../components/ContentEditor";
import FormItem from "../../../components/FormItem";
import { defaultHtmlFooter } from "./defaultHtmlFooter";
import { validationSchema } from "./GeneralForm.schema";
import { GeneralFormFields, GeneralFormProps } from "./GeneralForm.types";

const GeneralForm = ({ brand }: GeneralFormProps) => {
  const initialValues = {
    app_name: brand.app_name,
    web_footer_html: {
      blocks: [
        {
          type: "raw",
          data: { html: brand.web_footer_html ?? defaultHtmlFooter },
        },
      ],
    },
  };

  const { setValue, watch, handleSubmit, control } = useForm<GeneralFormFields>(
    {
      defaultValues: initialValues,
      //@ts-expect-error TODO: Resolve these type errors (yup upgrade most likely required.)
      resolver: yupResolver(validationSchema),
    }
  );

  const onReset = () => {
    setValue("web_footer_html", {
      blocks: [
        {
          type: "raw",
          data: { html: defaultHtmlFooter },
        },
      ],
    });
  };

  const { mutateAsync: updateBrand } = useUpdateBrand();

  const onSubmit = async (values: any) => {
    try {
      await updateBrand({
        id: brand.id,
        app_name: values.app_name,
        web_footer_html: values.web_footer_html.blocks[0].data.html,
        brand_attributes: {},
      });
      notification.success({
        message: "Success",
        description: Notifications.UpdateCustomization.success,
      });
    } catch (error) {
      notification.error({
        message: "Error",
        description: `${Notifications.UpdateCustomization.error} ${
          (error as PortalClientError)?.errors?.[0]?.detail
        }`,
      });
    }
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(onSubmit)}>
      <Space direction="vertical" size={24}>
        <Space direction="vertical" size={24}>
          <Space direction="vertical" size={16} style={{ height: 152 }}>
            <Typography.Title level={5} style={{ margin: 0 }}>
              App Name
            </Typography.Title>
            <Typography.Text type="secondary">
              App name is displayed on the Login screen (e.g. Log in to App
              Name). It can be a web app or mobile app.
            </Typography.Text>
            <Space direction="vertical" style={{ width: 320 }}>
              <FormItem control={control} name="app_name" required>
                <Input maxLength={30} placeholder="Enter App name" />
              </FormItem>
            </Space>
          </Space>
          <Space direction="vertical" size={16} style={{ width: 725 }}>
            <Typography.Title level={5} style={{ margin: 0 }}>
              App Footer
            </Typography.Title>
            <Typography.Text type="secondary">
              App footer is displayed on all screens in the app/web.
            </Typography.Text>
            <Space direction="vertical" size={16} style={{ width: "100%" }}>
              <HStack
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography.Text strong>HTML Editor</Typography.Text>
                <Button onClick={onReset}>Reset to default</Button>
              </HStack>
              <VStack height="300" overflow="auto" width="100%">
                <Form.Item name="web_footer_html">
                  <ContentEditor
                    data={watch("web_footer_html")}
                    onChange={(v) => {
                      setValue("web_footer_html", v, {
                        shouldValidate: true,
                        shouldDirty: true,
                      });
                      setValue("web_footer_html_html", {
                        html: editorToHtml().parse(v).join(""),
                      });
                    }}
                  />
                </Form.Item>
              </VStack>
            </Space>
          </Space>
        </Space>
        <Button htmlType="submit" style={{ margin: "0 4px" }} type="primary">
          Save
        </Button>
      </Space>
    </Form>
  );
};
export default GeneralForm;
