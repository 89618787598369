import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { JsonApiResponse } from "../types";
import { AdvancedSurvey } from "./types";

const useGetAdvancedSurveys = <
  TData extends {} = JsonApiResponse<AdvancedSurvey[]>
>(
  queryOptions?: UseQueryOptions<
    JsonApiResponse<AdvancedSurvey[]>,
    PortalClientError,
    TData
  >
) => {
  const client = useClient();

  return useQuery<JsonApiResponse<AdvancedSurvey[]>, PortalClientError, TData>(
    ["advanced_surveys"],
    () => client.get("/offers/advanced_surveys"),
    {
      staleTime: 5 * 60 * 1000,
      refetchOnWindowFocus: false,
      ...queryOptions,
    }
  );
};

export default useGetAdvancedSurveys;
