import { Menu } from "antd";
import { NavLink } from "react-router-dom";

import roles from "../../constants/roles";
import AuthorizedContent from "../Authorized/AuthorizedContent";
import FeatureFlaggedContent from "../FeatureFlaggedContent/";

const SideNavigationMenu: React.FC = () => {
  return (
    <Menu mode="inline" theme="dark">
      <AuthorizedContent roles={[roles.adminsAdmin]}>
        <Menu.Item key="admins">
          <NavLink to="/admins">Administrators</NavLink>
        </Menu.Item>
      </AuthorizedContent>
      <FeatureFlaggedContent experimentName="tenant_ps_applications">
        <AuthorizedContent roles={[roles.applicationAdmin]}>
          <Menu.Item key="applications">
            <NavLink to="/applications">Applications</NavLink>
          </Menu.Item>
        </AuthorizedContent>
      </FeatureFlaggedContent>

      <FeatureFlaggedContent experimentName="tenant_article_admin">
        <AuthorizedContent roles={[roles.superAdmin]}>
          <Menu.Item key="articles">
            <NavLink to="/articles">Articles</NavLink>
          </Menu.Item>
        </AuthorizedContent>
      </FeatureFlaggedContent>

      <FeatureFlaggedContent experimentName="tenant_consent_econsent">
        <AuthorizedContent roles={[roles.applicationAdmin]}>
          <Menu.Item key="consents">
            <NavLink to="/consents">Consents</NavLink>
          </Menu.Item>
        </AuthorizedContent>
      </FeatureFlaggedContent>

      <FeatureFlaggedContent experimentName="tenant_email_admin">
        <AuthorizedContent roles={[roles.superAdmin]}>
          <Menu.Item key="communications">
            <NavLink to="/communications">Communications</NavLink>
          </Menu.Item>
        </AuthorizedContent>
      </FeatureFlaggedContent>

      <FeatureFlaggedContent experimentName="tenant_ps_branding">
        <AuthorizedContent roles={[roles.applicationAdmin]}>
          <Menu.Item key="app_settings">
            <NavLink to="/app_settings">App Settings</NavLink>
          </Menu.Item>
        </AuthorizedContent>
      </FeatureFlaggedContent>

      <FeatureFlaggedContent experimentName="tenant_pj_experiments">
        <AuthorizedContent roles={[roles.experimentConfigAdmin]}>
          <Menu.Item key="experiments">
            <NavLink to="/experiments">Experiments</NavLink>
          </Menu.Item>
        </AuthorizedContent>
      </FeatureFlaggedContent>

      <FeatureFlaggedContent experimentName="tenant_ps_image_library">
        <AuthorizedContent roles={[roles.imageManager]}>
          <Menu.Item key="images">
            <NavLink to="/images">Image Library</NavLink>
          </Menu.Item>
        </AuthorizedContent>
      </FeatureFlaggedContent>

      <FeatureFlaggedContent experimentName="tenant_in_insight_types">
        <AuthorizedContent roles={[roles.insightAdmin]}>
          <Menu.Item key="insight_types">
            <NavLink to="/insight_types">Insight Types</NavLink>
          </Menu.Item>
        </AuthorizedContent>
      </FeatureFlaggedContent>

      <FeatureFlaggedContent experimentName="tenant_pj_programs">
        <AuthorizedContent roles={[roles.programAdmin]}>
          <Menu.Item key="programs">
            <NavLink to="/programs">Programs</NavLink>
          </Menu.Item>
        </AuthorizedContent>
      </FeatureFlaggedContent>

      <FeatureFlaggedContent experimentName="tenant_na_offers">
        <AuthorizedContent roles={[roles.offerAdmin]}>
          <Menu.Item key="offers">
            <NavLink to="/offers">Offers</NavLink>
          </Menu.Item>
        </AuthorizedContent>
      </FeatureFlaggedContent>

      <FeatureFlaggedContent experimentName="tenant_pj_recurring">
        <AuthorizedContent roles={[roles.offerAdmin]}>
          <Menu.Item key="recurring">
            <NavLink to="/recurring">Recurring</NavLink>
          </Menu.Item>
        </AuthorizedContent>
      </FeatureFlaggedContent>

      <FeatureFlaggedContent experimentName="tenant_support_users">
        <AuthorizedContent roles={[roles.userManager]}>
          <Menu.Item key="users">
            <NavLink to="/users">Users</NavLink>
          </Menu.Item>
        </AuthorizedContent>
      </FeatureFlaggedContent>

      <FeatureFlaggedContent experimentName="tenant_cards_deep_link">
        <AuthorizedContent roles={[roles.deepLinkAdmin]}>
          <Menu.Item key="deep_links">
            <NavLink to="/deep_links">Deep Links</NavLink>
          </Menu.Item>
        </AuthorizedContent>
      </FeatureFlaggedContent>

      <AuthorizedContent roles={[roles.superAdmin]}>
        <Menu.Item key="customization">
          <NavLink to="/customization">Customization</NavLink>
        </Menu.Item>
      </AuthorizedContent>
      <AuthorizedContent roles={[roles.superAdmin]}>
        <Menu.Item key="workspace_settings">
          <NavLink to="/workspace_settings">Workspace Settings</NavLink>
        </Menu.Item>
      </AuthorizedContent>
      <Menu.Item key="logout">
        <NavLink to="/logout">Logout</NavLink>
      </Menu.Item>
    </Menu>
  );
};

export default SideNavigationMenu;
