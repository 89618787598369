import { Alert, Space, Spin, Switch, Table, notification } from "antd";
import { ColumnsType } from "antd/es/table";
import React from "react";

import Notifications from "../../assets/data/notifications";
import { useExperiments, useUpdateExperiment } from "../../client";
import useWorkspaceSettings, {
  TenantFeatureFlagDescription,
} from "../../client/hooks/workspaceSettings/useWorkspaceSettings";
import { ColumnSearchProps } from "../../tables/filters";

const Features: React.FC = () => {
  const { data: experimentsData } = useExperiments();

  const {
    data: workspaceSettings,
    isLoading: isLoadingWorkspaceSettings,
    isError: isErrorWorkspaceSettings,
  } = useWorkspaceSettings();

  const { mutate: updateFeature, isLoading: updateIsLoading } =
    useUpdateExperiment({
      onSuccess: () => {
        notification.success({
          message: "Success",
          description: Notifications.UpdateFeature.success,
        });
      },
      onError: () => {
        notification.error({
          message: "Error",
          description: `${Notifications.UpdateFeature.error}`,
        });
      },
    });

  const findExperimentByKey = (featureKey: string) =>
    experimentsData?.data?.find((experiment) => experiment.name === featureKey);

  function fetchToggleSettingValue(feature: TenantFeatureFlagDescription) {
    const experiment = findExperimentByKey(feature.key);
    return experiment?.active;
  }

  function setSettingToggle(
    feature: TenantFeatureFlagDescription,
    isActive: boolean
  ) {
    const experiment = findExperimentByKey(feature.key);
    if (experiment) {
      updateFeature({ ...experiment, active: isActive });
    }
  }

  const featureTableColumns: ColumnsType<TenantFeatureFlagDescription> = [
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      sorter: (a: { category: string }, b: { category: any }) =>
        a.category.localeCompare(b.category),
      ...ColumnSearchProps("category"),
    },
    {
      title: "Feature",
      dataIndex: "feature",
      key: "feature",
      sorter: (a: { feature: string }, b: { feature: any }) =>
        a.feature.localeCompare(b.feature),
      ...ColumnSearchProps("feature"),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      sorter: (a: { description: string }, b: { description: any }) =>
        a.description.localeCompare(b.description),
      ...ColumnSearchProps("description"),
    },
    {
      title: "Application",
      dataIndex: "application",
      key: "application",
      ...ColumnSearchProps("application"),
    },
    {
      title: "Status",
      dataIndex: "active",
      key: "active",

      render: (_value: boolean, row: TenantFeatureFlagDescription) => {
        return (
          <Switch
            checked={fetchToggleSettingValue(row)}
            disabled={updateIsLoading}
            onChange={(isActive) => setSettingToggle(row, isActive)}
          />
        );
      },
    },
  ];
  return (
    <Space
      direction="vertical"
      size={16}
      style={{ width: "100%", padding: "24px" }}
    >
      {isLoadingWorkspaceSettings ? (
        <Spin size="large" tip="Loading" />
      ) : isErrorWorkspaceSettings ? (
        <Alert message={Notifications.Features.error} type="error" />
      ) : (
        <Table
          columns={featureTableColumns}
          dataSource={workspaceSettings?.data.tenant_feature_flag_descriptions}
          key="features"
        />
      )}
    </Space>
  );
};

export default Features;
