import { serialise } from "kitsu-core";

import {
  ButtonsOutcomeQuestionConfiguration,
  CheckboxOutcomeQuestionConfiguration,
  OutcomeQuestion,
  OutcomeQuestionType,
  UpdateOutcomeQuestion,
} from "../../client";

/**
 * Serialise a OutcomeQuestion to json-api schema.
 * @param outcomeQuestion The outcome question to serialise to json-api schema
 * @returns json-api formatted object for a POST/PUT/PATCH request.
 */
export const getOutcomeQuestionPayload = (
  outcomeQuestion: OutcomeQuestion
): UpdateOutcomeQuestion => {
  return serialise("outcome_question", {
    ...outcomeQuestion,
    response_schema: buildResponseSchema(outcomeQuestion),
  });
};

const responseSchemaStructures: { [key in OutcomeQuestionType]: any } = {
  checkbox_group: {
    type: "array",
    items: {
      type: "string",
      enum: [],
    },
  },
  radiobuttons: {
    type: "string",
    enum: [],
  },
  buttons: {
    type: "string",
    enum: [],
  },
  preemptive_ai_external_link: {
    type: "boolean",
  },
};

/**
 * Build the json-api validation schema for a OutcomeQuestion.
 * @param outcomeQuestion The outcome question to build the schema for.
 * @returns json-api formatted object that follows the json-api validation rules.
 */
export const buildResponseSchema = (outcomeQuestion: OutcomeQuestion): any => {
  const structure = responseSchemaStructures[outcomeQuestion.ui_element];
  switch (outcomeQuestion.ui_element) {
    case OutcomeQuestionType.CheckboxGroup:
      const checkboxConfiguration =
        outcomeQuestion.configuration as CheckboxOutcomeQuestionConfiguration;
      structure.items.enum = checkboxConfiguration.checkboxes.map(
        (checkbox) => checkbox.value
      );
      break;
    case OutcomeQuestionType.RadioButtons:
      const radioConfiguration =
        outcomeQuestion.configuration as CheckboxOutcomeQuestionConfiguration;
      structure.enum = radioConfiguration.checkboxes.map(
        (checkbox) => checkbox.value
      );
      break;
    case OutcomeQuestionType.Buttons:
      const buttonsConfiguration =
        outcomeQuestion.configuration as ButtonsOutcomeQuestionConfiguration;
      structure.enum = buttonsConfiguration.buttons.map(
        (button) => button.value
      );
      break;
  }
  return structure;
};
