import { Button, notification, Space, Typography } from "antd";
import { Formik } from "formik";
import { Form } from "formik-antd";
import React from "react";

import Notifications from "../../../assets/data/notifications";
import { PortalClientError } from "../../../client";
import useAddBrandImages, {
  AddBrandImagesPayload,
} from "../../../client/hooks/brands/useAddBrandImages";
import { Image } from "../../../client/hooks/images/types";
import SelectImageButton from "../../../components/SelectImageButton/SelectImageButton";
import { schema } from "./LogosForm.schema";
import { Logos, LogosFormProps } from "./LogosForm.types";

/**
 * We will never have File objects as default values, so this makes TypeScript
 *   happy.
 */

const LogosForm: React.FC<LogosFormProps> = ({ brand }) => {
  const { mutateAsync: addBrandImages, isLoading } = useAddBrandImages();

  const handleSubmit = async (values: Partial<Logos>) => {
    try {
      const logoFiles: AddBrandImagesPayload = {
        id: brand.id,
        favicon_image_id: values.favicon_image?.data?.id ?? null,
        login_image_id: values.login_image?.data?.id ?? null,
        primary_logo_rectangle_image_id:
          values.primary_logo_rectangle_image?.data?.id ?? null,
        primary_logo_square_image_id:
          values.primary_logo_square_image?.data?.id ?? null,
      };
      await addBrandImages(logoFiles);
      notification.success({
        message: "Success",
        description: Notifications.UpdateCustomization.success,
      });
    } catch (error) {
      notification.error({
        message: "Error",
        description: `${Notifications.UpdateCustomization.error} ${
          (error as PortalClientError)?.errors?.[0]?.detail
        }`,
      });
    }
  };

  return (
    <Formik<Logos>
      enableReinitialize
      initialValues={{
        primary_logo_rectangle_image: brand.primary_logo_rectangle_image,
        primary_logo_square_image: brand.primary_logo_square_image,
        favicon_image: brand.favicon_image,
        login_image: brand.login_image,
      }}
      onSubmit={handleSubmit}
      validateOnMount
      validationSchema={schema}
    >
      {({ isValid, setFieldValue, dirty, isSubmitting, values }) => (
        <Form>
          <Form.Item name="primary_logo_rectangle_image">
            <Space
              data-testid="select-image-primary-logo-rectangular"
              direction="vertical"
              size={16}
            >
              <Typography.Title level={5} style={{ marginBottom: 0 }}>
                Primary Logo (Rectangular)
              </Typography.Title>
              <Typography.Text type="secondary">
                Primary logo is displayed where there are rectangular logo
                placements.
                <br />
                Recommended dimensions: 640px x 480px, 4:3 aspect ratio.
                Supports PNG, JPEG, and SVG files. Max file size = 1MB.
              </Typography.Text>
              <SelectImageButton
                hasPreview
                image={values.primary_logo_rectangle_image?.data}
                kind="Branding::Images::Logo"
                onClearImage={() =>
                  setFieldValue("primary_logo_rectangle_image", null)
                }
                onSelectImage={(image: Image) =>
                  setFieldValue("primary_logo_rectangle_image", { data: image })
                }
              />
            </Space>
          </Form.Item>

          <Form.Item name="primary_logo_square_image">
            <Space
              data-testid="select-image-primary-logo-square"
              direction="vertical"
              size={16}
            >
              <Typography.Title level={5} style={{ marginBottom: 0 }}>
                Primary Logo (Square)
              </Typography.Title>
              <Typography.Text type="secondary">
                Primary logo is displayed where there are square logo
                placements.
                <br />
                Recommended dimensions: 400px x 400px, 1:1 aspect ratio.
                Supports PNG, JPEG, and SVG files. Max file size = 1MB.
              </Typography.Text>
              <SelectImageButton
                hasPreview
                image={values.primary_logo_square_image?.data}
                kind="Branding::Images::Logo"
                onClearImage={() =>
                  setFieldValue("primary_logo_square_image", null)
                }
                onSelectImage={(image: Image) =>
                  setFieldValue("primary_logo_square_image", { data: image })
                }
              />
            </Space>
          </Form.Item>

          <Form.Item name="favicon_image">
            <Space
              data-testid="select-image-favicon"
              direction="vertical"
              size={16}
            >
              <Typography.Title level={5} style={{ marginBottom: 0 }}>
                Favicon
              </Typography.Title>
              <Typography.Text type="secondary">
                Favicon is displayed in browser tabs.
                <br />
                Recommended dimensions: 32px x 32px. Supports PNG, JPEG, and SVG
                files. Max file size = 1MB.
              </Typography.Text>
              <SelectImageButton
                hasPreview
                image={values.favicon_image?.data}
                kind="Branding::Images::Favicon"
                onClearImage={() => setFieldValue("favicon_image", null)}
                onSelectImage={(image: Image) =>
                  setFieldValue("favicon_image", { data: image })
                }
              />
            </Space>
          </Form.Item>

          <Form.Item name="login_image">
            <Space
              data-testid="select-login-image"
              direction="vertical"
              size={16}
            >
              <Typography.Title level={5} style={{ marginBottom: 0 }}>
                Login Image
              </Typography.Title>
              <Typography.Text type="secondary">
                Login image is displayed in the login screen.
                <br />
                Recommended dimensions: 32px x 32px (TBD). Supports png, pdf and
                svg files. Max file size = 1MB.
              </Typography.Text>
              <SelectImageButton
                hasPreview
                image={values.login_image?.data}
                kind="Branding::Images::Login"
                onClearImage={() => setFieldValue("login_image", null)}
                onSelectImage={(image: Image) =>
                  setFieldValue("login_image", { data: image })
                }
              />
            </Space>
          </Form.Item>

          <Form.Item name="buttons">
            <Button
              disabled={!isValid || !dirty || isSubmitting}
              htmlType="submit"
              loading={isLoading}
              style={{ margin: "0 4px" }}
              type="primary"
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      )}
    </Formik>
  );
};
export default LogosForm;
