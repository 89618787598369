import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import type { PortalClientError } from "../..";
import { useClient } from "../../ClientContext";
import type { JsonApiResponse } from "../types";
import type { Brand } from "./types";

export type AddBrandImagesPayload = {
  id: number;
  favicon_image_id?: number | null;
  login_image_id?: number | null;
  primary_logo_square_image_id?: number | null;
  primary_logo_rectangle_image_id?: number | null;
};

const useAddBrandImages = (
  queryOptions?: UseMutationOptions<
    JsonApiResponse<Brand>,
    PortalClientError,
    AddBrandImagesPayload
  >
) => {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation(
    async (payload) => {
      return await client.put<
        any,
        JsonApiResponse<Brand>,
        AddBrandImagesPayload
      >("/brand", payload);
    },
    {
      ...queryOptions,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(["brands"]);
        queryOptions?.onSuccess?.(data, variables, context);
      },
    }
  );
};

export default useAddBrandImages;
