import { Button, Card, Col, Row, Typography } from "antd";
import { FormikProps, useField } from "formik";
import { DatePicker, Form, InputNumber, Select, TimePicker } from "formik-antd";
import moment from "moment";
import React from "react";

import { OutcomeDiary, Schedule } from "../../client";
import MonthView from "./ScheduleBuilderViews/MonthView";
import WeekView from "./ScheduleBuilderViews/WeekView";
import YearView from "./ScheduleBuilderViews/YearView";
import { formatPacificTime } from "./utils";

export interface ScheduleBuilderProps {
  // Used if any fields inside the schedule builder need to map to fields outside of the schedule object
  alternateFieldPaths?: { [key in keyof Partial<Schedule>]: string };
  formik: FormikProps<OutcomeDiary>;
  path: string;
  onClear?: () => void;
}

const ScheduleBuilderViews: { [tab: string]: React.FC<ScheduleBuilderProps> } =
  {
    week: WeekView,
    month: MonthView,
    year: YearView,
  };

const ScheduleBuilder: React.FC<ScheduleBuilderProps> = ({
  alternateFieldPaths,
  formik,
  path,
  onClear,
}) => {
  const [field] = useField<Schedule | null>(path);
  const CurrentView = ScheduleBuilderViews[field.value?.every ?? "day"];
  return (
    <Card title="Recurrance Setting">
      <Row style={{ width: "100%" }}>
        <Typography.Text style={{ marginBottom: 16, marginLeft: 10 }}>
          All fields are in Pacific Time
        </Typography.Text>
        <Col span={24}>
          <Form.Item
            label="Starting"
            name={alternateFieldPaths?.starts ?? `${path}.starts`}
          >
            <DatePicker
              allowClear
              format={(date) => formatPacificTime(date)}
              name={alternateFieldPaths?.starts ?? `${path}.starts`}
              showTime={{ use12Hours: true }}
            />
          </Form.Item>
          <Form.Item label="Repeat Every" name={`${path}.interval`}>
            <Row>
              <Col span={6}>
                <InputNumber
                  min={1}
                  name={`${path}.interval`}
                  style={{ width: "100%" }}
                />
              </Col>
              <Col offset={1}>
                <Select
                  name={`${path}.every`}
                  onChange={() => {
                    formik.setFieldValue(`${path}.hour`, undefined);
                    formik.setFieldValue(`${path}.day`, undefined);
                    formik.setFieldValue(`${path}.mday`, undefined);
                    formik.setFieldValue(`${path}.month`, undefined);
                    formik.setFieldValue(`${path}.year`, undefined);
                  }}
                  style={{ minWidth: 75 }}
                >
                  <Select.Option value="hour">Hours</Select.Option>
                  <Select.Option value="day">Days</Select.Option>
                  <Select.Option value="week">Weeks</Select.Option>
                  <Select.Option value="month">Months</Select.Option>
                  <Select.Option value="year">Years</Select.Option>
                </Select>
              </Col>
            </Row>
          </Form.Item>
          {CurrentView && <CurrentView formik={formik} path={path} />}
          <Form.Item label="Repeat At" name={`${path}.at`}>
            <Row align="middle">
              <TimePicker
                allowClear={false}
                format="h:mm A"
                name={`${path}.at`}
                onChange={(value) => {
                  if (value)
                    formik.setFieldValue(`${path}.at`, [
                      [value.hours(), value.minutes(), 0],
                    ]);
                }}
                showSecond={false}
                use12Hours
                value={
                  field.value?.at?.[0]?.length === 3
                    ? moment
                        .utc()
                        .hours(field.value.at[0][0])
                        .minutes(field.value.at[0][1])
                    : undefined
                }
              />
            </Row>
          </Form.Item>
          <Form.Item
            label="Ending"
            name={alternateFieldPaths?.until ?? `${path}.ending`}
          >
            <DatePicker
              allowClear
              format={(date) => formatPacificTime(date)}
              name={alternateFieldPaths?.until ?? `${path}.until`}
              showTime={{ use12Hours: true }}
            />
          </Form.Item>
          {onClear && (
            <Button danger onClick={onClear}>
              Clear
            </Button>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default ScheduleBuilder;
