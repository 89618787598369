import { Result, Space, Spin } from "antd";

import useWorkspaceSettings from "../../client/hooks/workspaceSettings/useWorkspaceSettings";
import WorkspaceSettingsForm from "../../forms/WorkspaceSettingsForm";

const Home: React.FC = () => {
  const { data, isLoading, isError, error } = useWorkspaceSettings();
  const workspaceSettings =
    data?.data ||
    (error?.status === 404
      ? {
          admin_login_text: "",
          admin_welcome_message: "",
          admin_favicon_url: "",
          admin_logo_url: "",
          tenant_feature_flag_descriptions: [],
        }
      : undefined);
  return (
    <Space
      direction="vertical"
      size={16}
      style={{ width: "100%", padding: "24px" }}
    >
      {isLoading ? (
        <Spin size="large" spinning />
      ) : isError && error!.status !== 404 ? (
        <Result
          status="error"
          subTitle="There was a problem loading the workspace settings for this tenant. Please try again later."
          title="Error loading workspace settings"
        />
      ) : (
        <WorkspaceSettingsForm
          settings={{
            admin_login_text: workspaceSettings!.admin_login_text,
            admin_welcome_message: workspaceSettings!.admin_welcome_message,
            admin_favicon_url: workspaceSettings!.admin_favicon_url,
            admin_logo_url: workspaceSettings!.admin_logo_url,
            tenant_feature_flag_descriptions:
              workspaceSettings!.tenant_feature_flag_descriptions,
          }}
        />
      )}
    </Space>
  );
};

export default Home;
