import React from "react";

import { OutcomeQuestion, OutcomeQuestionType } from "../../../client";
import ButtonsOutcomeQuestion from "./ButtonsOutcomeQuestion";
import CheckboxOutcomeQuestion from "./CheckboxOutcomeQuestion";

export interface OutcomeQuestionConfigurationProps {
  disabled?: boolean;
  outcomeQuestion: OutcomeQuestion;
}

const OutcomeQuestionConfiguration: React.FunctionComponent<
  OutcomeQuestionConfigurationProps
> = ({ disabled, outcomeQuestion }) => {
  switch (outcomeQuestion.ui_element) {
    case OutcomeQuestionType.Buttons:
      return (
        <ButtonsOutcomeQuestion
          disabled={disabled}
          outcomeQuestion={outcomeQuestion}
        />
      );
    case OutcomeQuestionType.RadioButtons:
    case OutcomeQuestionType.CheckboxGroup:
      return (
        <CheckboxOutcomeQuestion
          disabled={disabled}
          outcomeQuestion={outcomeQuestion}
        />
      );
    case OutcomeQuestionType.PreemptiveAIExternalLink:
      return null;
    default:
      return <React.Fragment>Unknown outcome question type.</React.Fragment>;
  }
};

export default OutcomeQuestionConfiguration;
