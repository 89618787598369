import * as yup from "yup";

const fileTypeTest = () =>
  yup
    .mixed()
    .test(
      "fileType",
      "You can only upload JPEG, PNG, or SVG images.",
      (value) =>
        value?.type &&
        ["image/jpeg", "image/jpg", "image/png", "image/svg+xml"].includes(
          value.type
        )
    );

/**
 * Returns a Yup field representing an image file upload.
 */
const imageFile = () =>
  yup.lazy((value) =>
    value instanceof File ? fileTypeTest() : yup.string().optional()
  );

export default imageFile;
