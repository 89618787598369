import { PageHeader, Result, Space, Spin, Tabs } from "antd";
import React, { useCallback } from "react";
import { NavLink, RouteChildrenProps, useHistory } from "react-router-dom";

import useBrand from "../../client/hooks/brands/useBrand";
import { invert } from "../../utils/objects";
import FontsTab from "./tabs/FontsTab";
import GeneralTab from "./tabs/GeneralTab";
import LogosTab from "./tabs/LogosTab";

const pageTitle = "Customizations";
const pathsToTabs = {
  "/customization": "general",
  "/customization/logos": "logos",
  "/customization/fonts": "fonts",
  "/customization/colors": "colors",
} as const;
const tabsToPaths = invert(pathsToTabs);
const defaultTab = "logos";

type TabKey = keyof typeof tabsToPaths;
type ValidPath = keyof typeof pathsToTabs;

const CustomizationView: React.FC<RouteChildrenProps> = ({ match }) => {
  const activeBrandQuery = useBrand();
  // A null value explicitly means that there is no active brand, as opposed to
  //   `undefined`, which means it's still loading or there was an error.
  const activeBrand = activeBrandQuery.data?.data;
  const history = useHistory();

  const activeTab = pathsToTabs[match!.path as ValidPath] || defaultTab;
  const isLoading = activeBrandQuery.isLoading;
  const error = activeBrandQuery.error;

  const handleTabChange = useCallback(
    (tabKey: string) => {
      history.push(tabsToPaths[tabKey as TabKey]);
    },
    [history]
  );

  return (
    <React.Fragment>
      <PageHeader
        breadcrumb={{
          itemRender: (route, params) => (
            <NavLink to={route.path} {...params}>
              {route.breadcrumbName}
            </NavLink>
          ),
          routes: [
            {
              path: "/",
              breadcrumbName: "Home",
            },
            {
              path: match!.path,
              breadcrumbName: pageTitle,
            },
          ],
        }}
        style={{ backgroundColor: "#fff" }}
        title={pageTitle}
      />
      <Space
        direction="vertical"
        size={16}
        style={{ width: "100%", padding: "24px" }}
      >
        {isLoading ? (
          <Spin size="large" spinning />
        ) : error ? (
          <Result
            status="error"
            subTitle="There was a problem loading customization settings for this tenant. Please try again later."
            title="Error loading customization settings"
          />
        ) : (
          <Tabs
            activeKey={activeTab}
            items={[
              {
                key: "general",
                label: "General",
                children: <GeneralTab brand={activeBrand!} />,
              },
              {
                key: "logos",
                label: "Images",
                children: <LogosTab brand={activeBrand!} />,
              },
              {
                key: "fonts",
                label: "Fonts",
                children: <FontsTab brand={activeBrand!} />,
              },
              {
                key: "colors",
                label: "Colors",
                children: <div>Colors</div>,
              },
            ]}
            onChange={handleTabChange}
          />
        )}
      </Space>
    </React.Fragment>
  );
};
export default CustomizationView;
