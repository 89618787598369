import { Button, Space, notification } from "antd";
import { Formik } from "formik";
import { Form } from "formik-antd";
import React from "react";

import Notifications from "../../../assets/data/notifications";
import { PortalClientError } from "../../../client";
import { BrandFontAttributes } from "../../../client/hooks/brands/types";
import useUpdateBrand from "../../../client/hooks/brands/useUpdateBrand";
import FontGroup from "../../../components/FontGroup";
import { schema } from "./FontsForm.schema";
import { FontsFormProps } from "./FontsForm.types";

const FontsForm: React.FC<FontsFormProps> = ({ brand }) => {
  const { mutateAsync: updateBrand } = useUpdateBrand();

  const handleSubmit = async (values: BrandFontAttributes) => {
    try {
      await updateBrand({
        id: brand.id,
        brand_attributes: values,
      });
      notification.success({
        message: "Success",
        description: Notifications.UpdateCustomization.success,
      });
    } catch (error) {
      notification.error({
        message: "Error",
        description: `${Notifications.UpdateCustomization.error} ${
          (error as PortalClientError)?.errors?.[0]?.detail
        }`,
      });
    }
  };

  return (
    <Formik<BrandFontAttributes>
      enableReinitialize
      initialValues={brand.brand_attributes}
      onSubmit={handleSubmit}
      validateOnMount
      validationSchema={schema}
    >
      {({ isValid, dirty, isSubmitting }) => (
        <Form>
          <Space direction="vertical" size={16}>
            <FontGroup
              description="The Heading 1 text is applied to headlines, including: Sign Up Screen."
              keyName="heading_1"
              title="Heading 1"
            />
            <FontGroup
              description="The Heading 2 text is applied to sub-headlines, including: Survey Questions, Screen Titles, Total Points."
              keyName="heading_2"
              title="Heading 2"
            />
            <FontGroup
              description="The Heading 3 text is applied to titles, including: Card Titles, Settings Section Titles, Profile Section Titles, Profile Name, Points earned (in ledger), # of Cards Completed Text, Long-form Copy Header."
              keyName="heading_3"
              title="Heading 3"
            />
            <FontGroup
              description="The Heading 4 text is applied to Profile PII Titles, Profile Menu Items, Settings Menu Items, Page Header Title, Button Text, Points History (Day, Week, Month Labels), Ledger Entry Titles, Active Profile Tab Title (Profile / Settings)."
              keyName="heading_4"
              title="Heading 4"
            />
            <FontGroup
              description="The Heading 5 text is applied to Legal Screen Titles."
              keyName="heading_5"
              title="Heading 5"
            />
            <FontGroup
              description="The Body Text 1 is applied to Card Description, Default Profile Tab Title (Profile / Settings), Card Answer Choices Text and  Long-form Copy Text (e.g. Article Content)."
              keyName="body_text_1"
              title="Body Text 1"
            />
            <FontGroup
              description="The Body Text 2 is applied to Legal Screen Body Text."
              keyName="body_text_2"
              title="Body Text 2"
            />
            <FontGroup
              description="The Body Text 3 is applied to the copy on the Landing Pages."
              keyName="body_text_3"
              title="Body Text 3"
            />
            <FontGroup
              description="The Accent Text is applied to Card Label Text, Card Expiration, Profile PII, Points History Link, Cards Completed Text in Points Ledger, Ledger entry descriptions, Captions, Inline Errors."
              keyName="accent_text"
              title="Accent Text"
            />
          </Space>

          <Form.Item name="buttons">
            <Button
              disabled={!isValid || !dirty || isSubmitting}
              htmlType="submit"
              loading={isSubmitting}
              style={{ margin: "0 4px" }}
              type="primary"
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      )}
    </Formik>
  );
};
export default FontsForm;
